<template>
  <div>
    <script
      type="module"
      src="public/js/blinkid-in-browser/blinkid-in-browser.esm.js"
    ></script>

    <blinkid-in-browser
      @scanSuccess="onScanSuccess"
      @ready="onEngineLoad"
      ref="blinkidScanner"
    >
    </blinkid-in-browser>
  </div>
</template>

<script>
import BaseSubmitButton from "armory-sdk/src/components/base/BaseSubmitButton";
import * as BlinkIDSDK from "@microblink/blinkid-in-browser-sdk";
import { Base64 } from "js-base64";

export default {
  name: "IdScanner",
  extends: BaseSubmitButton,
  props: {
    inputId: String,
    licenseKey: String,
    engineLocation: String,
    workerLocation: String,
  },
  data() {
    return {
      nextScreen: null,
      showMenuHiderDiv: true,
    };
  },

  mounted() {
    this.setupScanner();
  },
  methods: {
    ...BaseSubmitButton.methods,

    setupScanner() {
      this.showBlinkIdUI(false);

      let scanner = this.$refs.blinkidScanner;

      scanner.licenseKey = this.licenseKey;
      scanner.engineLocation = this.engineLocation;
      scanner.workerLocation = this.workerLocation;
      scanner.recognizers = ["BlinkIdMultiSideRecognizer"];
      scanner.scanFromImage = false;
      scanner.recognizerOptions = {
        BlinkIdMultiSideRecognizer: {
          // returnFullDocumentImage: true,
          // returnFaceImage: true,
          // returnSignatureImage: true,
        },
      };
    },

    onEngineLoad() {
      this.hideCameraToolbar();
      this.clickScanButton();
      this.showBlinkIdUI(true);
    },

    hideCameraToolbar() {
      const cameraToolbar = queryShadowDomRecursively(
        this.$refs.blinkidScanner.shadowRoot,
        "mb-camera-toolbar"
      )[0];
      if (cameraToolbar) {
        cameraToolbar.style.display = "none";
      }
    },
    clickScanButton() {
      let startScanButton = queryShadowDomRecursively(
        this.$refs.blinkidScanner.shadowRoot,
        "mb-button"
      )[0].shadowRoot.querySelector("button");
      startScanButton.click();
    },

    onScanSuccess(event) {
      this.showBlinkIdUI(false);

      let result = event.detail.recognizer;

      console.log(result);

      if (result.state === BlinkIDSDK.RecognizerResultState.Empty) {
        return;
      }

      // Inform the user about results
      console.log("BlinkID Multi-side recognizer results", result);

      const firstName =
        result.firstName.latin ||
        result.firstName.cyrillic ||
        result.firstName.arabic ||
        result.mrz.secondaryID;

      const lastName =
        result.lastName.latin ||
        result.lastName.cyrillic ||
        result.lastName.arabic ||
        result.mrz.primaryID;

      const dateOfBirth = new Date(
        result.dateOfBirth.year || result.mrz.dateOfBirth.year,
        result.dateOfBirth.month || result.mrz.dateOfBirth.month,
        result.dateOfBirth.day || result.mrz.dateOfBirth.day,
      );

      const documentNumber =
        result.documentNumber.latin ||
        result.documentNumber.cyrillic ||
        result.documentNumber.arabic ||
        result.mrz.documentNumber.identityCardNumber;

      const dateOfExpiry = new Date(
        result.dateOfExpiry.year || result.mrz.dateOfExpiry.year,
        result.dateOfExpiry.month || result.mrz.dateOfExpiry.month,
        result.dateOfExpiry.day || result.mrz.dateOfExpiry.day
      );

      const sex = result.sex.latin || result.sex.cyrillic || result.sex.arabic;

      const nationality =
        result.nationality.latin ||
        result.nationality.cyrillic ||
        result.nationality.arabic;

      const address =
        result.address.latin ||
        result.address.cyrillic ||
        result.address.arabic;

      const images = {
        fullDocumentFrontImage: rawImageToBase64(
          result.fullDocumentFrontImage.rawImage
        ),
        fullDocumentBackImage: rawImageToBase64(
          result.fullDocumentBackImage.rawImage
        ),
        faceImage: rawImageToBase64(result.faceImage.rawImage),
        signatureImage: rawImageToBase64(result.signatureImage.rawImage),
      };

      this.nextScreen = {
        firstName: firstName,
        lastName: lastName,
        dateOfBirth: dateOfBirth,
        nationality: nationality,
        documentNumber: documentNumber,
        dateOfExpiry: dateOfExpiry,
        sex: sex,
        address: address,
        images: images,
      };
      this.clicked();
    },

    showBlinkIdUI(enable) {
      if (enable) {
        this.$refs.blinkidScanner.style.display = "";
      } else {
        this.$refs.blinkidScanner.style.display = "none";
      }
    },
  },
};

function queryShadowDomRecursively(root, selector) {
  const elements = Array.from(root.querySelectorAll(selector));

  Array.from(root.querySelectorAll("*")).forEach((element) => {
    if (element.shadowRoot) {
      elements.push(...queryShadowDomRecursively(element.shadowRoot, selector));
    }
  });

  return elements;
}

function rawImageToBase64(rawImage) {
  if (!rawImage?.data?.length || rawImage.width <= 0 || rawImage.height <= 0) {
    return "";
  }

  // Create an HTML canvas element and set its dimensions
  const canvas = document.createElement("canvas");
  canvas.width = rawImage.width;
  canvas.height = rawImage.height;

  // Get the 2D context from the canvas and put the image data into it
  const ctx = canvas.getContext("2d");
  ctx.putImageData(rawImage, 0, 0);

  // Use the canvas's toDataURL method to get the base64-encoded PNG image
  return canvas.toDataURL("image/png");
}
</script>
